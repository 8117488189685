import { ErrorHandler, Injectable } from '@angular/core';
import * as rg4js from 'raygun4js';
import { environment } from './../../../environments/environment';

const doc = (document as any);

// Set Raygun metadata
rg4js('apiKey', doc.raygunApiKey);
rg4js('options', {
    disableErrorTracking: !environment.production || doc.logToRaygun !== 'true',
    disablePulse: true,
    debugmode: !doc.raygunApiKey
    // ignore3rdPartyErrors: true having this enabled breaks reporting, somehow
});
rg4js('setVersion', doc.wfVersion);

/**
 * Checks if the error comes from failing to load chunks and reloads the webpage, 
 *  otherwise prints the error to the console and sends it to raygun to be logged.
 */
@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
    handleError(e: any) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(e.message)) {
            console.log("Failed to load chunks, reloading page.");
            window.location.reload();
        }
        else {
            console.error(e);
            rg4js('send', {
                error: e,
                tags: [
                    'Angular',
                    doc.raygunInstance,
                    doc.raygunEnvironment,
                ]
            });
        }
    }
}
