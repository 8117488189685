import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
      // const nextReq = req.clone();
      // nextReq.headers.set('Cache-Control', 'no-cache')
      // nextReq.headers.set('Pragma', 'no-cache');
      // nextReq.headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

      let headers = req.headers.set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
      const cloneReq = req.clone({headers});

      return next.handle(cloneReq);
    }
}